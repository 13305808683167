<template>
  <div id="app" style="display: none" ref="vueapp">
    <!-- 打包成功后要将dist里面的index.html的id为app的标签的style display样式改为none -->
    <router-view />
  </div>
</template>
<script>
import router from "./router";
import { jssdk } from "@/config/wx";
router.afterEach(() => {
  // hospital
  jssdk();
});
export default {
  mounted() {
    this.$refs.vueapp.style.display = "block";
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
</style>
