<template>
  <div>
    <div class="business" v-if="pc == 'pc'">
      <Nav titlename="business" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="center">
        <div :class="[{ toppc: gettop }]" :style="{ top: '60px' }">
          <div class="select">
            <div
              v-for="(item, index) in selectarr"
              :key="index"
              class="fontbox point"
            >
              <div @click="changeselect(item)">{{ item.direction }}</div>
              <div class="line" v-if="item.type == select"></div>
            </div>
          </div>
        </div>
        <div style="height: 60px" v-show="gettop"></div>
        <div v-for="(item1, index1) in selectarr" :key="index1">
          <div
            class="showcontent"
            v-if="select == 'doctor' && item1.type == 'doctor'"
          >
            <div
              class="showbox"
              :style="'backgroundColor:' + val.backgroudcolor"
              v-for="(val, idx) in item1.content"
              :key="idx"
            >
              <div class="showcontent-box">
                <div class="showcontent-box-left">
                  <div class="logoimg"><img :src="val.logo" alt="" /></div>
                  <div class="showcontent-box-font">
                    {{ val.content }}
                  </div>
                  <div class="buttons">
                    <div v-if="val.docbutton">
                      <div
                        v-for="(item2, index2) in val.docbutton"
                        :key="index2"
                        class="buttonbox"
                      >
                        <div
                          :class="item2.flag ? 'button' : 'buttonnoraml'"
                          :style="'backgroundColor:' + val.color"
                          @click="tolink(item2.linkurl)"
                        >
                          <img :src="item2.imgurl" alt="" class="button-img" />
                          <div>{{ item2.title }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="QR"
                      v-if="val.QRcode"
                      @mousemove="val.QRcode.show = true"
                      @mouseout="val.QRcode.show = false"
                    >
                      <div
                        class="QRcontent"
                        :style="'backgroundImage:url(' + val.QRcode.inpic + ')'"
                      ></div>
                      <div
                        class="QRhover"
                        v-show="val.QRcode.show"
                        :style="
                          'backgroundImage:url(' + val.QRcode.outpic + ')'
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    class="docpic"
                    :style="{
                      backgroundImage: 'url(' + val.pic + ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="showcontent"
            v-if="item1.type == 'patient' && select == 'patient'"
          >
            <div
              class="showbox"
              :style="'backgroundColor:' + val.backgroudcolor"
              v-for="(val, idx) in item1.content"
              :key="idx"
            >
              <div class="showcontent-box">
                <div class="showcontent-box-left">
                  <div class="logoimg">
                    <img :src="val.logo" alt="" />
                  </div>
                  <div class="showcontent-box-font">
                    {{ val.content }}
                  </div>
                  <div class="buttons">
                    <div v-if="val.docbutton">
                      <div
                        v-for="(item2, index2) in val.docbutton"
                        :key="index2"
                        class="buttonbox"
                      >
                        <div
                          :class="item2.flag ? 'button' : 'buttonnoraml'"
                          :style="'backgroundColor:' + val.color"
                          @click="tolink(item2.linkurl)"
                        >
                          <img :src="item2.imgurl" alt="" class="button-img" />
                          <div>{{ item2.title }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="QR"
                      v-if="val.QRcode"
                      @mousemove="val.QRcode.show = true"
                      @mouseout="val.QRcode.show = false"
                    >
                      <div
                        class="QRcontent"
                        :style="'backgroundImage:url(' + val.QRcode.inpic + ')'"
                      ></div>
                      <div
                        class="QRhover"
                        v-show="val.QRcode.show"
                        :style="
                          'backgroundImage:url(' + val.QRcode.outpic + ')'
                        "
                      ></div>
                    </div>
                    <div class="QRimg" v-if="val.QRpic">
                      <img :src="val.QRpic" alt="" />
                    </div>
                  </div>
                  <div
                    class="docpic"
                    :style="{
                      backgroundImage: 'url(' + val.pic + ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="showcontent"
            v-if="item1.type == 'company' && select == 'company'"
          >
            <div
              class="showbox"
              :style="'backgroundColor:' + val.backgroudcolor"
              v-for="(val, idx) in item1.content"
              :key="idx"
            >
              <div class="showcontent-box">
                <div class="showcontent-box-left">
                  <div class="logoimg">
                    <img :src="val.logo" alt="" />
                  </div>
                  <div class="showcontent-box-font">
                    {{ val.content }}
                  </div>
                  <div class="buttons">
                    <div v-if="val.docbutton">
                      <div
                        v-for="(item2, index2) in val.docbutton"
                        :key="index2"
                        class="buttonbox"
                      >
                        <div
                          :class="item2.flag ? 'button' : 'buttonnoraml'"
                          :style="'backgroundColor:' + val.color"
                          @click="tolink(item2.linkurl)"
                        >
                          <img :src="item2.imgurl" alt="" class="button-img" />
                          <div>{{ item2.title }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="QR"
                      v-if="val.QRcode"
                      @mousemove="val.QRcode.show = true"
                      @mouseout="val.QRcode.show = false"
                    >
                      <div
                        class="QRcontent"
                        :style="'backgroundImage:url(' + val.QRcode.inpic + ')'"
                      ></div>
                      <div
                        class="QRhover"
                        v-show="val.QRcode.show"
                        :style="
                          'backgroundImage:url(' + val.QRcode.outpic + ')'
                        "
                      ></div>
                    </div>
                    <div class="QRimg" v-if="val.QRpic">
                      <img :src="val.QRpic" alt="" />
                    </div>
                  </div>
                  <div
                    class="docpic"
                    :style="{
                      backgroundImage: 'url(' + val.pic + ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <div
              :style="'backgroundColor:' + val1.backgroudcolor"
              v-for="(val1, idx1) in item1.contentother"
              :key="idx1 * 1 + 20"
            >
              <div
                class="contentother"
                :style="{
                  marginBottom:
                    item1.contentother.length - 1 == idx1 ? '5rem' : '0rem',
                }"
              >
                <div class="contentother-font">{{ val1.font }}</div>
                <img :src="val1.pic" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <div>
      <div class="mbbusiness" v-if="pc == 'mobil'">
        <Nav titlename="business" @jumpMenu="jumpMenu"></Nav>
        <Banner
          :pic="bannerimg"
          :title="bannertitle"
          :content="bannercontent"
        ></Banner>
        <div class="mbcenter">
          <div
            class="mbselect"
            :class="[{ top: gettop }]"
            :style="{ top: '60px' }"
          >
            <div
              v-for="(item, index) in selectarr"
              :key="index"
              class="mbfontbox"
              :style="{ color: select == item.type ? '#454556' : '#9898A2' }"
            >
              <div @click="changeselect(item)">{{ item.direction }}</div>
              <div class="mbline" v-if="item.type == select"></div>
            </div>
          </div>
          <div style="height: 45px" v-show="gettop"></div>
          <div v-for="(item1, index1) in selectarr" :key="index1">
            <div
              class="mbshowcontent"
              v-if="select == 'doctor' && item1.type == 'doctor'"
            >
              <div
                class="mbshowbox"
                :style="'backgroundColor:' + val.mbbackgroundcolor"
                v-for="(val, idx) in item1.content"
                :key="idx"
              >
                <div class="mbshowcontent-box">
                  <div class="mbshowcontent-box-left">
                    <div class="mblogoimg"><img :src="val.logo" alt="" /></div>
                    <div class="mbshowcontent-box-font">
                      {{ val.content }}
                    </div>
                    <div class="mbbuttons">
                      <div v-if="val.docbutton">
                        <div
                          v-for="(item2, index2) in val.docbutton"
                          :key="index2"
                          class="mbbuttonbox"
                        >
                          <div
                            :class="item2.flag ? 'mbbutton' : 'mbbuttonnormal'"
                            :style="'backgroundColor:' + val.color"
                            @click="tolink(item2.linkurl)"
                          >
                            <img
                              :src="item2.imgurl"
                              alt=""
                              class="mbbutton-img"
                            />
                            <div>{{ item2.title }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mbshowcontent"
              v-if="item1.type == 'patient' && select == 'patient'"
            >
              <div
                class="mbshowbox"
                :style="'backgroundColor:' + val.mbbackgroundcolor"
                v-for="(val, idx) in item1.content"
                :key="idx"
              >
                <div class="mbshowcontent-box">
                  <div class="mbshowcontent-box-left">
                    <div class="mblogoimg">
                      <img :src="val.logo" alt="" />
                    </div>
                    <div class="mbshowcontent-box-font">
                      {{ val.content }}
                    </div>
                    <div class="mbbuttons">
                      <div v-if="val.docbutton">
                        <div
                          v-for="(item2, index2) in val.docbutton"
                          :key="index2"
                          class="mbbuttonbox"
                        >
                          <div
                            :class="item2.flag ? 'mbbutton' : 'mbbuttonnormal'"
                            :style="'backgroundColor:' + val.color"
                            @click="tolink(item2.linkurl)"
                          >
                            <img
                              :src="item2.imgurl"
                              alt=""
                              class="mbbutton-img"
                            />
                            <div>{{ item2.title }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="val.mbdocbutton">
                        <div
                          v-for="(item2, index2) in val.mbdocbutton"
                          :key="index2"
                          class="mbbuttonbox"
                        >
                          <div
                            v-if="vappshow && item2.ghid && weixin"
                            :class="item2.flag ? 'mbbutton' : 'mbbuttonnormal'"
                            :style="'backgroundColor:' + val.color"
                          >
                            <wx-open-launch-weapp
                              id="launch-btn"
                              :username="item2.ghid"
                            >
                              <script type="text/wxtag-template">
                                <style>
                                  .mbbutton-img {
                                  width: 16px;
                                  height: 16px;
                                  margin-right: 8px;
                                position:absolute;
                                top:0;
                                bottom:0;
                                margin:auto 0;
                                }
                                .mbbutton-title{
                                  display:inline-block;
                                  color:white;
                                  line-height:40px;
                                  font-size:14px;
                                  margin-left:24px;
                                }
                                                            </style>
                                                            <image
                                                                src="https://bosdev.liangyihui.net/temp/1618898707.png"
                                                                alt=""
                                                                class="mbbutton-img"
                                                              ></image>
                                                              <div class="mbbutton-title">{{ item2.title }}</div>
                              </script>
                            </wx-open-launch-weapp>
                          </div>
                          <div
                            v-if="item2.linkurl && weixin"
                            :class="item2.flag ? 'mbbutton' : 'mbbuttonnormal'"
                            :style="'backgroundColor:' + val.color"
                            @click="tolink(item2.linkurl)"
                          >
                            <img
                              :src="item2.imgurl"
                              alt=""
                              class="mbbutton-img"
                            />
                            <div>{{ item2.title }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="docpic"
                      :style="{
                        backgroundImage: 'url(' + val.pic + ')',
                      }"
                    ></div> -->
                    <div class="QRimg" v-if="val.QRpic && !weixin">
                      <img :src="val.QRpic" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mbshowcontent"
              v-if="item1.type == 'company' && select == 'company'"
            >
              <div
                class="mbshowbox"
                :style="'backgroundColor:' + val.mbbackgroundcolor"
                v-for="(val, idx) in item1.content"
                :key="idx"
              >
                <div class="mbshowcontent-box">
                  <div class="mbshowcontent-box-left">
                    <div class="mblogoimg">
                      <img :src="val.logo" alt="" />
                    </div>
                    <div class="mbshowcontent-box-font">
                      {{ val.content }}
                    </div>
                    <div class="mbbuttons">
                      <div v-if="val.docbutton">
                        <div
                          v-for="(item2, index2) in val.docbutton"
                          :key="index2"
                          class="mbbuttonbox"
                          @click="tolink(item2.linkurl)"
                        >
                          <div
                            :class="item2.flag ? 'mbbutton' : 'mbbuttonnormal'"
                            :style="'backgroundColor:' + val.color"
                          >
                            <img
                              :src="item2.imgurl"
                              alt=""
                              class="mbbutton-img"
                            />
                            <div>{{ item2.title }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :style="'backgroundColor:' + val1.backgroudcolor"
                v-for="(val1, idx1) in item1.contentother"
                :key="idx1 * 1 + 20"
              >
                <div class="mbcontentother">
                  <div class="mbcontentother-font">{{ val1.font }}</div>
                  <img :src="val1.pic" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Banner from "@/components/Banner.vue";
import wx from "weixin-js-sdk";
import axios from "axios";
export default {
  components: {
    Nav,
    Footer,
    Banner,
  },
  watch: {
    select: async function (newval) {
      if (newval == "patient") {
        let res = await axios.post(
          "https://doctor.liangyihui.net/doctor/tp/Index/jsk",
          {
            url: location.href.split("#")[0],
          }
        );
        if (res) {
          // console.log('123123123123123123')
          // console.log(res);
          if (res.status == 200) {
            wx.config({
              debug: false,
              appId: res.data.appId,
              timestamp: res.data.timestamp * 1,
              nonceStr: res.data.nonceStr,
              signature: res.data.signature,
              jsApiList: ["chooseImage", "previewImage"],
              openTagList: ["wx-open-launch-weapp"],
            });
            wx.ready(() => {
              this.vappshow = true;
            });
          } else {
            alert(res.msg);
          }
        }
      } else {
        this.vappshow = false;
      }
      // console.log(111);
    },
  },
  methods: {
    changeselect(item) {
      this.select = item.type;
      // console.log(item)
      let sestroge = { className: item.type, title: "/Business/" };
      sessionStorage.setItem("nav", JSON.stringify(sestroge));
      if (this.pc == "pc" && this.gettop) {
        let dom = document.getElementsByClassName("center")[0];
        window.scrollTo(0, dom.offsetTop - 60);
      }
      if (this.pc == "mobil" && this.gettop) {
        let dom = document.getElementsByClassName("mbcenter")[0];
        window.scrollTo(0, dom.offsetTop - 60);
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    handleLaunchFn(e) {
      console.log(e);
    },
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },
    tolink(url) {
      // console.log(url);
      location.href = url;
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
    settabmbtop() {
      setTimeout(() => {
        let searcheight = 0;
        searcheight =
          document.getElementsByClassName("mbselect")[0].offsetTop - 60;
        // console.log(document.getElementsByClassName('tag')[0])
        window.addEventListener("scroll", () => {
          if (window.pageYOffset - searcheight > 0) {
            this.gettop = true;
          } else {
            this.gettop = false;
          }
        });
      }, 800);
    },
    settappctop() {
      setTimeout(() => {
        let searcheight = 0;
        searcheight =
          document.getElementsByClassName("select")[0].offsetTop - 60;
        // console.log(document.getElementsByClassName('tag')[0])
        window.addEventListener("scroll", () => {
          if (window.pageYOffset - searcheight > 0) {
            this.gettop = true;
          } else {
            this.gettop = false;
          }
        });
      }, 800);
    },
    jumpMenu(className) {
      if (window.location.pathname != className.item.url) {
        this.$router.push(className.item.url);
        return;
      }
      let tonavJson = sessionStorage.getItem("nav");
      let tonav = null;
      if (tonavJson) {
        tonav = JSON.parse(tonavJson);
        this.select = tonav.className;
      }
      // let top = document.querySelectorAll(`.${className}`)[0].offsetTop - 60;
      // window.scrollTo(0, top);
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.pc = this.IsPC();
    this.weixin = this.isWeiXin();
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618889432.png";
    }
  },
  mounted() {
    let that = this;
    this.pc = this.IsPC();
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618889432.png";
    }
    this.weixin = this.isWeiXin();
    let tonavJson = sessionStorage.getItem("nav");
    // console.log(tonavJson+1111)
    let tonav = null;
    if (tonavJson) {
      tonav = JSON.parse(tonavJson);
      if (tonav.title != location.pathname) {
        sessionStorage.removeItem("nav");
        return;
      }
      that.select = tonav.className;
    } else {
      sessionStorage.removeItem("nav");
    }
    if (this.pc == "mobil") {
      this.$nextTick(() => {
        this.settabmbtop();
      });
    } else {
      this.$nextTick(() => {
        this.settappctop();
      });
    }
  },
  data() {
    return {
      weixin: null,
      vappshow: false,
      pc: null,
      gettop: false,
      bannerimg: "//bosdev.liangyihui.net/temp/1618974688.png",
      bannertitle: "肿瘤垂直领域数字化创新服务企业",
      bannercontent:
        "致力于打造中国最具影响力、最大规模的肿瘤一站式医患企数据化服务生态",
      select: "doctor",
      selectarr: [
        {
          direction: "面向医生",
          type: "doctor",
          content: [
            {
              mbbackgroundcolor: "#FFFFFF",
              backgroudcolor: "#FEFCFD",
              color: "#CD0070",
              logo: "//bosdev.liangyihui.net/temp/1617270235.png",
              pic: "//bosdev.liangyihui.net/temp/1618479343.png",
              content:
                "良医汇-肿瘤医生，为18万+中国肿瘤医生打造一站式学习和工作平台。覆盖全瘤种前沿资讯、公开课，各大肿瘤会议在线直播；多款医学工具免费使用；提供互联网医院入驻、患者管理，帮助肿瘤医生高效工作。",
              docbutton: [
                {
                  flag: "secialstyle",
                  imgurl: "//bosdev.liangyihui.net/temp/1617271684.png",
                  title: "苹果版下载",
                  linkurl:
                    "https://apps.apple.com/cn/app/liang-yi-hui-zhong-liu-zi-xun/id1034265664",
                },
                {
                  flag: "secialstyle",
                  imgurl: "//bosdev.liangyihui.net/temp/1617271688.png",
                  title: "安卓版下载",
                  linkurl:
                    "https://a.app.qq.com/o/simple.jsp?pkgname=net.liangyihui.app",
                },
              ],
              QRcode: {
                outpic: "//bosdev.liangyihui.net/temp/1618799050.png",
                inpic: "//bosdev.liangyihui.net/temp/1618797931.png",
                show: false,
              },
            },
            {
              mbbackgroundcolor: "#FAFAFA",
              backgroudcolor: "#F9FAFD",
              color: "#525FA8",
              logo: "//bosdev.liangyihui.net/temp/1618802114.png",
              pic: "//bosdev.liangyihui.net/temp/1618802146.png",
              content:
                "良医汇-患者管理，基于患教场景，围绕医生&患者交互习惯，构建高效的患者管理服务平台。为医生和患者建立高效的沟通与反馈机制，帮助医院、药企更好、更快、更便捷地进行患者服务与管理。",
              docbutton: [
                {
                  imgurl: "//bosdev.liangyihui.net/temp/1618802071.png",
                  title: "点此访问",
                  linkurl:
                    "http://cdnmini.liangyihui.net/hjd#/login?redirect=%2Fdashboard",
                },
              ],
            },
            {
              mbbackgroundcolor: "#FFFFFF",
              backgroudcolor: "#F9FCFD",
              color: "#32ADC7",
              logo: "//bosdev.liangyihui.net/temp/1617348931.png",
              pic: "//bosdev.liangyihui.net/temp/1617347687.png",
              content:
                "良医汇-数据管家（临床科研与患者管理平台），颠覆了传统的临床研究模式，提供临床医生的个人云端临床积累平台，并实现智能管理、实时数据分析、帮助临床医生实现“每次临床积累，都转化为科研价值，更快、更好、更省力地完成高质量的临床研究成果输出。”",
              docbutton: [
                {
                  imgurl: "//bosdev.liangyihui.net/temp/1617349319.png",
                  title: "点此访问",
                  linkurl: "http://data.liangyihui.net/login.html",
                },
              ],
            },
          ],
        },
        {
          direction: "面向患者",
          type: "patient",
          content: [
            {
              mbbackgroundcolor: "#FFFFFF",
              backgroudcolor: "#FBFDFF",
              color: "#2FA7FB",
              logowith: "",
              logoheight: "",
              logo: "//bosdev.liangyihui.net/temp/1618810513.png",
              pic: "//bosdev.liangyihui.net/temp/1618810773.png",
              content:
                "良医汇-全球肿瘤防治中心小程序，汇聚肿瘤领域防治资讯、专家团队和诊疗服务，打造一体化肿瘤诊疗中心，成就医生未来，守护患者健康。",
              QRpic: "//bosdev.liangyihui.net/temp/1618811511.png",
              mbdocbutton: [
                {
                  ghid: "gh_22eb6fc44ce9",
                  imgurl: "//bosdev.liangyihui.net/temp/1618898707.png",
                  title: "点此访问",
                },
              ],
            },
            {
              mbbackgroundcolor: "#FAFAFA",
              backgroudcolor: "#FCFEFF",
              color: "#2FA7FB",
              logo: "//bosdev.liangyihui.net/temp/1618810522.png",
              pic: "//bosdev.liangyihui.net/temp/1618812591.png",
              content:
                "良医汇-互联网医院官网，以肿瘤单病种为中心汇聚最新诊疗指南、新药动态，整合全球优质医疗资源，更好地服务中国肿瘤。",
              docbutton: [
                {
                  imgurl: "//bosdev.liangyihui.net/temp/1618812482.png",
                  title: "点此访问",
                  linkurl: "https://www.lyhcc.cn/",
                },
              ],
            },
            {
              mbbackgroundcolor: "#FFFFFF",
              backgroudcolor: "#FBFFFB",
              color: "#3FB34B",
              logo: "//bosdev.liangyihui.net/temp/1618813009.png",
              pic: "//bosdev.liangyihui.net/temp/1618812908.png",
              content:
                "良医汇-患者指南，传播科学肿瘤防治理念，为肿瘤患者提供科普信息、求医问药指南。是良医汇旗下肿瘤患者资讯服务平台。",
              QRpic: "//bosdev.liangyihui.net/temp/1618813005.png",
              mbdocbutton: [
                {
                  linkurl:
                    "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA5MTA0MjMwOQ==&scene=110#wechat_redirect",
                  imgurl: "//bosdev.liangyihui.net/temp/1618898707.png",
                  title: "点此访问",
                },
              ],
            },
            {
              mbbackgroundcolor: "#FAFAFA",
              backgroudcolor: "#FFFEFA",
              color: "#FFD800",
              logo: "//bosdev.liangyihui.net/temp/1618813239.png",
              pic: "//bosdev.liangyihui.net/temp/1618813231.png",
              content:
                "良医汇-预防指南，面向全部人群，传递科学防癌健康资讯，解答相关疑问，帮助症状管理，打造最用心的癌症预防科普平台。",
              QRpic: "//bosdev.liangyihui.net/temp/1618813235.png",
              mbdocbutton: [
                {
                  imgurl: "//bosdev.liangyihui.net/temp/1618898707.png",
                  title: "点此访问",
                  linkurl:
                    "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0NDE3NDkwOA==&scene=110#wechat_redirect",
                },
              ],
            },
          ],
        },
        {
          direction: "面向企业",
          type: "company",
          content: [
            {
              mbbackgroundcolor: "#FFFFFF",
              backgroudcolor: "#FAFDFD",
              color: "#07938E",
              logowith: "",
              logoheight: "",
              logo: "//bosdev.liangyihui.net/temp/1618813425.png",
              pic: "//bosdev.liangyihui.net/temp/1618813437.png",
              content:
                "良医汇-精准互动系统：集用户、互动、营销、数据为一体，应用在肿瘤医生产品矩阵的CRM产品。基于海量数据分析的医生用户画像，全方位的科室医院信息，精准内容轻松抵达，帮助药企实现学术信息快精准、快速、广泛的覆盖。",
              docbutton: [
                {
                  imgurl: "//bosdev.liangyihui.net/temp/1618812482.png",
                  title: "点此访问",
                  linkurl:
                    "http://lyhdm.liangyihui.net/start/index.html#/user/login/redirect=%2F",
                },
              ],
            },
          ],
          contentother: [
            {
              font: "肿瘤医药产品全生命周期的数字化解决方案",
              pic: "//bosdev.liangyihui.net/temp/1618815672.png",
            },
            {
              font: "肿瘤医药产品客户教育和市场营销的全方位解决方案",
              pic: "//bosdev.liangyihui.net/temp/1618815679.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "../assets/px.less";
.business {
  min-height: calc(100vh - @px60);
  text-align: left;
}
.select {
  display: flex;
  width: @px1200;
  margin: 0 auto;
  height: @px60;
  font-size: @px20;
  color: #454556;
  line-height: @px60;
}
.fontbox {
  position: relative;
  margin-right: @px80;
}
.line {
  width: 100%;
  border-radius: @px2;
  height: @px4;
  bottom: @px0;
  position: absolute;
  background-color: #cd0070;
}
.showcontent {
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  .showbox {
    height: @px680;
  }
  &-box {
    height: @px680;
    position: relative;
    width: @px1200;
    margin: 0 auto;
    &-font {
      text-align: justify;
      width: @px400;
      margin-top: @px60;
    }
    &-left {
      overflow: hidden;
      font-size: @px16;
      line-height: @px36;
      color: #454556;
    }
  }
  .buttonbox {
    float: left;
    margin: 0 @px20 0 0;
  }
  .button {
    cursor: pointer;
    padding: 0 @px16;
    box-sizing: border-box;
    width: @px144;
    height: @px50;
    display: flex;
    align-items: center;
    font-size: @px16;
    color: white;
    border-radius: @px4;
    &-img {
      width: @px20;
      height: @px20;
      margin-right: @px12;
    }
  }
  .docpic {
    position: absolute;
    left: @px440;
    top: 0;
    width: @px760;
    height: @px680;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .logoimg {
    margin-top: @px140;
    height: @px88;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    img {
      height: 100%;
    }
  }
}
.QR {
  cursor: pointer;
  border-radius: @px4;
  display: inline-block;
  width: @px48;
  height: @px48;
  border: @px1 #cd0070 solid;
  margin: 0 @px20 0 0;
  position: absolute;
}
.QRcontent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: @px20;
  height: @px20;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.QRhover {
  position: absolute;
  left: -2.563rem;
  top: -8.75rem;
  width: @px132;
  height: @px132;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.buttons {
  margin-top: @px40;
}
.QRimg {
  width: @px132;
  height: @px132;
  img {
    width: 100%;
    height: 100%;
  }
}
.contentother {
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  width: @px1200;
  margin: 0 auto;
  box-sizing: border-box;
  img {
    width: 100%;
  }
  .contentother-font {
    margin: @px80 auto @px40;
    text-align: center;
    font-size: @px32;
    color: #454556;
    font-weight: bold;
    line-height: @px40;
  }
}
.mbbusiness {
  text-align: left;
}
.mbselect {
  padding: @px11 0 @px11 @px20;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border-bottom: @px1 solid #e5e5e8;
  .mbfontbox {
    position: relative;
    margin-right: @px20;
    font-size: @px15;
    line-height: @px22;
    color: #9898a2;
    font-weight: bold;
  }
  .mbline {
    width: @px30;
    border-radius: @px2;
    height: @px4;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.688rem;
    position: absolute;
    background-color: #cd0070;
  }
}
.mbshowbox {
  padding: @px40 @px20 @px60 @px20;
  width: 100%;
  box-sizing: border-box;
  .mbshowcontent-box-font {
    text-align: justify;
    color: #454556;
    line-height: @px26;
    font-size: @px14;
    margin-bottom: @px24;
  }
  .mblogoimg {
    height: @px56;
    margin-bottom: @px24;
    img {
      height: 100%;
    }
  }
}
.mbbuttons {
  overflow: hidden;
}
.mbbutton {
  border-radius: @px4;
  float: left;
  margin-right: @px24;
  padding: 0 @px16;
  box-sizing: border-box;
  width: @px126;
  height: @px40;
  display: flex;
  align-items: center;
  font-size: @px14;
  color: white;
  &-img {
    width: @px16;
    height: @px16;
    margin-right: @px8;
  }
}
.mbcontentother {
  padding: 0 @px20;
  margin-bottom: @px40;
  &-font {
    width: @px234;
    font-size: @px18;
    line-height: @px26;
    color: #454556;
    font-weight: bold;
    margin-bottom: @px16;
  }
  img {
    width: 100%;
  }
}
.top {
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #fff;
}
.toppc {
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
}
#launch-btn {
  border-radius: @px4;
  width: @px126;
  height: @px40;
  display: flex;
  align-items: center;
  color: white;
  overflow: hidden;
  position: relative;
}
.point {
  cursor: pointer;
}
.buttonnoraml {
  cursor: pointer;
  padding: 0 @px16;
  box-sizing: border-box;
  width: @px128;
  height: @px50;
  display: flex;
  align-items: center;
  font-size: @px16;
  color: white;
  border-radius: @px4;
  &-img {
    width: @px20;
    height: @px20;
    margin-right: @px12;
  }
}
.mbbuttonnormal {
  border-radius: @px4;
  float: left;
  margin-right: @px24;
  padding: 0 @px16;
  box-sizing: border-box;
  width: @px112;
  height: @px40;
  display: flex;
  align-items: center;
  font-size: @px14;
  color: white;
  &-img {
    width: @px16;
    height: @px16;
    margin-right: @px8;
  }
}
</style>
