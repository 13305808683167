<template>
  <div>
    <div class="NewMedia" v-if="pc == 'pc'">
      <Nav titlename="NewMedia" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="center">
        <div class="center-title">{{ title }}</div>
        <div class="picbox">
          <div
            v-for="(item, index) in picarray"
            :key="index"
            class="center-pic"
          >
            <img class="center-pic" :src="item" alt="" />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <div class="mbNewMedia" v-if="pc == 'mobil'">
      <Nav titlename="NewMedia" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="mbcenter">
        <div class="mbcenter-title">{{ title }}</div>
        <div class="mbpicbox">
          <div
            v-for="(item, index) in mbpicarray"
            :key="index"
            class="mbcenter-pic"
          >
            <img class="mbcenter-pic" :src="item" alt="" />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "../assets/px.less";
.NewMedia {
  text-align: left;
  min-height: calc(100vh - 60px);
  background-color: #fff;
}
.center {
  width: @px1200;
  margin: @px40 auto;
  &-title {
    text-align: center;
    font-size: @px32;
    line-height: @px40;
    color: #454556;
    font-weight: bold;
    margin-bottom: @px40;
  }
  .picbox {
    display: flex;
    flex-wrap: wrap;
  }
  &-pic {
    line-height: 0px;
    width: @px300;
    height: @px300;
  }
}
.mbNewMedia {
  text-align: left;
  .mbcenter {
    width: 100%;
    margin: @px40 auto;
    &-title {
      text-align: center;
      font-size: @px18;
      line-height: @px26;
      color: #454556;
      font-weight: bold;
      margin-bottom: @px16;
    }
    .mbpicbox {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      width: e("calc(100vw - 40px)");
    }
    &-pic {
      line-height: 0px;
      width: e("calc((100vw - 40px)/2)");
    }
  }
}
</style>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    Nav,
    Footer,
    Banner,
  },
  created() {
    window.scrollTo(0, 0);
    this.pc = this.IsPC();
    if (this.pc == "mobil") {
      this.bannerimg = "http://bosdev.liangyihui.net/temp/1618900277.png";
    }
  },
  mounted() {
    this.pc = this.IsPC();
    sessionStorage.removeItem("nav");
    if (this.pc == "mobil") {
      this.bannerimg = "http://bosdev.liangyihui.net/temp/1618900277.png";
    }
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
    jumpMenu(className) {
      if (window.location.pathname != className.item.url) {
        this.$router.push(className.item.url);
        return;
      }
    },
  },
  data() {
    return {
      pc: null,
      bannerimg: "//bosdev.liangyihui.net/temp/1618975323.png",
      bannertitle: "影响力领先的医学新媒体",
      bannercontent:
        "分享肿瘤领域最新进展，传播正确肿瘤防治理念，提高全社会对肿瘤的关注",
      title: "媒体矩阵",
      picarray: [
        "//bosdev.liangyihui.net/temp/1617249436.png",
        "//bosdev.liangyihui.net/temp/1617252922.png",
        "//bosdev.liangyihui.net/temp/1617252983.png",
        "//bosdev.liangyihui.net/temp/1617253002.png",
        "//bosdev.liangyihui.net/temp/1617253043.png",
        "//bosdev.liangyihui.net/temp/1617253048.png",
        "//bosdev.liangyihui.net/temp/1617253052.png",
        "//bosdev.liangyihui.net/temp/1617253103.png",
      ],
      mbpicarray: [
        "//bosdev.liangyihui.net/temp/1618900693.png",
        "//bosdev.liangyihui.net/temp/1618900697.png",
        "//bosdev.liangyihui.net/temp/1618900701.png",
        "//bosdev.liangyihui.net/temp/1618900733.png",
        "//bosdev.liangyihui.net/temp/1618900736.png",
        "//bosdev.liangyihui.net/temp/1618900739.png",
        "//bosdev.liangyihui.net/temp/1618900771.png",
        "//bosdev.liangyihui.net/temp/1618900775.png",
      ],
    };
  },
};
</script>
