<template>
  <div>
    <div class="bgbox" v-if="pc == 'pc'">
      <div class="bg">
        <div class="banner">
          <img :src="pic" alt="" class="banner-img" />
          <div class="bannerbox">
            <div class="banner-font banner-style">{{ title }}</div>
            <div class="banner-email banner-style">
              {{ content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mbbanner" v-if="pc == 'mobil'">
      <img :src="pic" alt="" class="mbbanner-img" />
      <div class="mbbannerbox">
        <div class="mbbanner-font mbbanner-style">{{ title }}</div>
        <div class="mbbanner-email mbbanner-style">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pc: null,
    };
  },
  props: {
    pic: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  created() {
    this.pc = this.IsPC();
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/px.less";
.banner {
  width: @px1200;
  height: @px400;
  margin: @px60 auto 0;
  position: relative;
  text-align: left;
  line-height: 0px;
  color: white;
  &-img {
    position: absolute;
    height: 100%;
    width: 120rem;
    top: 0;
    left: 0;
    margin: 0 -22.5rem;
  }
  .bannerbox {
    top: 35%;
    position: absolute;
    left: 0;
    right: 0;
    width: @px1200;
    margin: 0 auto;
    // top: 32px;
  }
  &-font {
    font-weight: bold;
    font-size: @px32;
    line-height: @px48;
  }
  &-email {
    margin-top: @px8;
    font-size: @px16;
    line-height: @px32;
  }
}
.mbbanner {
  margin-top: @px60;
  text-align: left;
  line-height: 0px;
  width: 100%;
  position: relative;
  color: white;
  &-img {
    width: 100%;
  }
  .mbbannerbox {
    top: 28.125%;
    position: absolute;
    left: 0;
    right: 0;
    width: e("calc(100vw - 40px)");
    margin: 0 auto;
    // top: 32px;
  }
  &-font {
    width: 100%;
    font-weight: bold;
    font-size: @px16;
    line-height: @px26;
  }
  &-email {
    width: 100%;
    margin-top: @px8;
    font-size: @px12;
    line-height: @px18;
  }
}
.bgbox {
  height: 460px;
  color: #fff;
  text-align: center;
}
.bg {
  position: absolute;
  width: 100%;
  min-width: @px1200;
  overflow-x: hidden;
  height: @px460;
  top: 0;
}
</style>
