<template>
  <div>
    <div class="Responsibility" v-if="pc == 'pc'">
      <Nav titlename="responsibility" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="center">
        <div
          v-for="(item, index) in responsibility"
          :key="index"
          :class="item.classname"
        >
          <div class="center-odd" v-if="index % 2 == 0">
            <div class="center-odd-fontbox">
              <div class="title">{{ item.title }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
            <div class="center-odd-img">
              <img class="center-odd-img" :src="item.pic" alt="" />
            </div>
          </div>
          <div class="center-odd" v-if="index % 2 == 1">
            <div class="center-odd-img">
              <img class="center-odd-img" :src="item.pic" alt="" />
            </div>
            <div class="center-odd-fontbox">
              <div class="title">{{ item.title }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <div class="mbResponsibility" v-if="pc == 'mobil'">
      <Nav titlename="responsibility" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="mbcenter">
        <div
          v-for="(item, index) in responsibility"
          :key="index"
          :class="item.classname"
        >
          <div class="mbcenter-fontbox">
            <div class="mbtitle">{{ item.title }}</div>
            <div class="mbcenter-img">
              <img :src="item.pic" alt="" />
            </div>
            <div class="mbcontent">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    Nav,
    Footer,
    Banner,
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
    jumpMenu(className) {
      if (window.location.pathname != className.item.url) {
        this.$router.push(className.item.url);
        return;
      }
      let top =
        document.querySelectorAll(`.${className.className}`)[0].offsetTop - 60;
      // console.log(className, top);
      window.scrollTo(0, top);
      sessionStorage.removeItem("nav");
    },
  },
  created() {
    this.pc = this.IsPC();
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618901423.png";
    }
  },
  mounted() {
    this.pc = this.IsPC();
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618901423.png";
      sessionStorage.removeItem("nav");
    }
    let tonavJson = sessionStorage.getItem("nav");
    let tonav = null;
    if (tonavJson) {
      tonav = JSON.parse(tonavJson);
      // console.log(tonav);
      if (tonav.title != location.pathname) {
        window.scrollTo(0, 0);
        sessionStorage.removeItem("nav");
        return;
      }
      setTimeout(() => {
        this.$nextTick(() => {
          let top =
            document.querySelectorAll(`.${tonav.className}`)[0].offsetTop - 60;
          window.scrollTo(0, top);
          sessionStorage.removeItem("nav");
        });
      }, 100);
    } else {
      window.scrollTo(0, 0);
    }
  },
  data() {
    return {
      pc: null,
      bannertitle: "更好地服务肿瘤患者",
      bannerimg: "//bosdev.liangyihui.net/temp/1618975393.png",
      bannercontent:
        "改善医疗资源分布不均衡，降低患者治疗费用，延长患者长期生存",
      responsibility: [
        {
          title: "抗疫行动",
          classname: "action",
          content:
            "良医汇在新冠疫情暴发伊始，第一时间在患者指南上线答疑平台，聚集千名肿瘤专家，在线解决数万名肿瘤患者疾病问题，获得行业一致好评。",
          pic: "//bosdev.liangyihui.net/temp/1617243960.png",
        },
        {
          title: "共建患者教育中心",
          classname: "centre",
          content:
            "良医汇已与11家肿瘤医院共建患者教育中心，以线上&线下联动模式提供专业的患者教育和服务。",
          pic: "//bosdev.liangyihui.net/temp/1618984016.png",
        },
        {
          title: "发行患者指南手册",
          classname: "handbook",
          content:
            "与中国临床肿瘤学会（CSCO）青年专家委员会共同翻译国内首套全瘤种肿瘤患者指南手册，免费发行数万册予医院和患者。",
          pic: "//bosdev.liangyihui.net/temp/1619150373.png",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "../assets/px.less";
.Responsibility {
  min-height: calc(100vh - 60px);
  text-align: left;
  background-color: #fff;
}
.center {
  width: @px1200;
  margin: @px40 auto @px40;
  &-odd {
    height: @px338;
    display: flex;
    width: @px1200;
    justify-content: space-between;
    align-items: center;
    &-fontbox {
      box-sizing: border-box;
      width: @px600;
      height: @px338;
      background-color: #fafafa;
      padding: @px80;
      color: #454556;

      .title {
        font-size: @px32;
        line-height: @px40;
        font-weight: bold;
      }
      .content {
        margin-top: @px24;
        font-size: @px16;
        line-height: @px24;
      }
    }
    &-img {
      width: @px600;
      height: @px338;
    }
  }
  .center-odd {
    margin-bottom: @px24;
  }
}
.mbResponsibility {
  text-align: left;
}
.mbcenter {
  margin-top: @px40;
  width: 100%;
  .mbtitle {
    font-size: @px18;
    line-height: @px26;
    font-weight: bold;
    text-align: center;
    color: #454556;
    margin-bottom: @px16;
  }
  &-fontbox {
    margin-bottom: @px40;
  }
  .mbcontent {
    font-size: @px14;
    line-height: @px20;
    padding: 0 @px20;
    color: #454556;
    width: 100%;
    box-sizing: border-box;
  }
  &-img {
    width: e("calc(100vw - 40px)");
    margin: 0 auto @px8;
    line-height: 0;
    img {
      width: 100%;
    }
  }
}
</style>
