import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

window.EventBus = new Vue();
const config1 = require("../src/service/config");
Vue.prototype.URL = config1;
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array(
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  );
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      document.title = to.meta.shorttitle;
      break;
    }
  }
  if (document.title != to.meta.shorttitle) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
