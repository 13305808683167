var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pc == 'pc')?_c('div',{staticClass:"home"},[_c('Nav',{attrs:{"titlename":"introduction"},on:{"jumpMenu":_vm.jumpMenu}}),_vm._m(0),_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"maintitle"},[_vm._v(_vm._s(_vm.introduceMsg.maintitle))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.introduceMsg.title))]),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.introduceMsg.message))])]),_c('div',{staticClass:"parttitle mv"},[_vm._v("使命及愿景")]),_c('div',{staticClass:"mvbox"},[_c('div',{staticClass:"mvbox-content"},_vm._l((_vm.mv),function(item,index){return _c('div',{key:index,staticClass:"mvbox-content-style"},[_c('img',{staticClass:"icon",attrs:{"src":item.pic,"alt":""}}),_c('div',[_c('div',{staticClass:"mvtitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"mvdetail"},[_vm._v(_vm._s(item.detail))])])])}),0)]),_c('div',{staticClass:"parttitle culturetitle"},[_vm._v("企业文化")]),_c('div',{staticClass:"culture"},_vm._l((_vm.culture),function(item,index){return _c('div',{key:index,staticClass:"culture-box",style:({
          backgroundImage: 'url(' + item.bg + ')',
        })},[_c('div',[_c('div',{staticClass:"culture-box-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"culture-box-content"},[_vm._v(_vm._s(item.content))])])])}),0),_c('div',{staticClass:"eventspart"},[_c('div',{staticClass:"eventspart-box"},[_c('div',{staticClass:"eventspart-title"},[_vm._v("发展大事记")]),_c('div',{staticClass:"rprogress"},_vm._l((_vm.time),function(item,index){return _c('div',{key:index,staticClass:"point",style:({
              color:
                item.status == _vm.choosetime
                  ? 'rgba(255, 255, 255, 1)'
                  : 'rgba(255, 255, 255, 0.5)',
            }),on:{"click":function($event){return _vm.changetime(item.status, index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"rprogress"},_vm._l((_vm.time),function(item,index){return _c('div',{key:index,staticClass:"ballbox"},[_c('div',{staticClass:"ball"},[_c('div',{staticClass:"ballcontent",style:({
                  backgroundColor:
                    item.status == _vm.choosetime ? 'rgba(255, 255, 255, 1)' : '',
                }),on:{"click":function($event){return _vm.changetime(item.status, index)}}})]),(index != _vm.time.length - 1)?_c('div',{staticClass:"line"}):_vm._e()])}),0)])]),_c('div',{staticClass:"substance"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.time1),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"timetitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"line1"}),_vm._l((item.bigevent),function(item1,index1){return _c('div',{key:index1,staticClass:"eventbox"},[_c('div',{staticClass:"timebox"},[_vm._v(_vm._s(item1.time))]),_c('div',{staticClass:"contentboxhome"},[_vm._v(_vm._s(item1.content))])])})],2)}),0)])]),_vm._m(1),_c('Footer')],1):_vm._e(),(_vm.pc == 'mobil')?_c('div',{staticClass:"mbhome"},[_c('Nav',{attrs:{"titlename":"introduction"}}),_vm._m(2),_c('div',{staticClass:"mbmaintitle"},[_vm._v(_vm._s(_vm.introduceMsg.maintitle))]),_c('div',{staticClass:"mbintroduce"},[_c('div',{staticClass:"mbtitle"},[_vm._v(_vm._s(_vm.introduceMsg.title))]),_c('div',{staticClass:"mbmessage"},[_vm._v(_vm._s(_vm.introduceMsg.message))])]),_c('div',{staticClass:"mbparttitle"},[_vm._v("使命及愿景")]),_c('div',{staticClass:"mbmvbox"},[_c('div',{staticClass:"mbmvbox-content"},_vm._l((_vm.mv),function(item,index){return _c('div',{key:index,staticClass:"mbmvbox-content-style",style:({ marginTop: _vm.mv.length - 1 == index ? '1rem' : '' })},[_c('img',{staticClass:"mbicon",attrs:{"src":item.pic,"alt":""}}),_c('div',{staticClass:"mbbox"},[_c('div',{staticClass:"mbmvtitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"mbmvdetail"},[_vm._v(_vm._s(item.detail))])])])}),0)]),_c('div',{staticClass:"mbparttitle"},[_vm._v("企业文化")]),_c('div',{staticClass:"mbculture"},_vm._l((_vm.culture),function(item,index){return _c('div',{key:index,staticClass:"mbculture-box",style:({
          backgroundImage: 'url(' + item.bg + ')',
        })},[_c('div',[_c('div',{staticClass:"mbculture-box-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"mbculture-box-content"},[_vm._v(_vm._s(item.content))])])])}),0),_c('div',{staticClass:"mbparttitle",staticStyle:{"marginBottom":"8px"}},[_vm._v("发展大事纪")]),_c('div',{staticClass:"mbrprogress"},_vm._l((_vm.time),function(item,index){return _c('div',{key:index,staticClass:"mbrprogress-box",style:({
          color: item.status == _vm.choosetime ? '#454556' : '#9898A2',
        }),on:{"click":function($event){return _vm.changetime(item.status, index)}}},[_vm._v(" "+_vm._s(item.title)+" "),(item.status == _vm.choosetime)?_c('div',{staticClass:"mbline"}):_vm._e()])}),0),_vm._l((_vm.time1),function(item,index){return _c('div',{key:index},[(item.status == _vm.choosetime)?_c('div',{staticClass:"mbeventbox"},_vm._l((item.bigevent),function(item1,index1){return _c('div',{key:index1,staticClass:"mbselectbox",style:({
            marginBottom: item.bigevent.length - 1 == index1 ? '0' : '1rem',
          })},[_c('div',{staticClass:"mbtimebox"},[_vm._v(_vm._s(item1.time))]),_c('div',{staticClass:"mbcontentboxhome"},[_vm._v(_vm._s(item1.content))])])}),0):_vm._e()])}),_c('div',{staticClass:"mbparttitle"},[_vm._v("合作伙伴")]),_vm._m(3),_c('div',{staticClass:"mbtap"},[_vm._v("*合作企业按企业英文名称首字母排序")]),_c('Footer')],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgbox"},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"banner"},[_c('img',{staticClass:"banner-img",attrs:{"src":"//bosdev.liangyihui.net/temp/1619175264.png","alt":""}}),_c('img',{staticClass:"banner-font",attrs:{"src":"//bosdev.liangyihui.net/temp/1619175217.png","alt":""}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"partners"},[_c('div',{staticClass:"eventspart-title"},[_vm._v("合作伙伴")]),_c('div',{staticClass:"partners-imgbox"},[_c('img',{staticClass:"partners-imgbox-img",attrs:{"src":"//rs-os-lyh-dt-publicread-picture-bosmetadata-test.liangyihui.net/document_N/JTsT-HvJU3WNt28iFH2q070q3moLVd3C2SeuLyJmyxJ8G0afM1sQtyfpRhu-DqVX/gCJOpz6854mVjfOv8xGrgjThFsKMyxPn.png","alt":""}})]),_c('div',{staticClass:"partners-footer"},[_vm._v("*合作企业按企业英文名称首字母排序")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mbbanner"},[_c('img',{staticClass:"mbbanner-img",attrs:{"src":"//bosdev.liangyihui.net/temp/1617850210.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mbpartners"},[_c('img',{attrs:{"src":"//rs-os-lyh-dt-publicread-picture-bosmetadata-test.liangyihui.net/document_N/JTsT-HvJU3WNt28iFH2q070q3moLVd3C2SeuLyJmyxJ8G0afM1sQtyfpRhu-DqVX/WXYLYt4iJ85dEyglkLy2hASVh0HR8s60.png","alt":""}})])}]

export { render, staticRenderFns }