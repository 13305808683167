<template>
  <div>
    <!-- pc -->
    <div class="content" v-if="pc == 'pc'">
      <div class="content-box">
        <div class="left_logo" @click="tolink"></div>
        <div class="right_ul">
          <div
            v-for="(item, index) in navarray"
            :key="index"
            class="right_li"
            :style="{ color: item.title == titlename ? '#CD0070' : '#454556' }"
            @click.stop="changepage(item)"
            @mousemove="showli(item)"
            @mouseleave="unshowli(item)"
          >
            <span> {{ item.name }}</span>
            <div class="rightline" v-if="item.title == titlename"></div>
            <div
              class="right_li-float"
              v-if="item.showitem"
              @mouseenter="showli(item)"
              @mouseleave="unshowli(item)"
            >
              <div
                class="right_li-float-item"
                v-for="(v, i) in item.detail"
                :key="i"
                @click.stop="menuBtn(v.mark, item)"
              >
                {{ v.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="mbcontent" v-if="pc == 'mobil'">
      <div class="mbcontent-box">
        <div class="mbleft_logo" @click="tolink"></div>
        <div
          class="mbrightflag"
          @click="topread"
          :style="{
            backgroundImage: isspread
              ? 'url(//bosdev.liangyihui.net/temp/1617778575.png)'
              : 'url(//bosdev.liangyihui.net/temp/1617777927.png)',
          }"
        ></div>
      </div>
      <div class="mb_ul" v-if="isspread">
        <div
          v-for="(item, index) in navarray"
          :key="index"
          class="mb_li"
          :style="{
            marginBottom: index != navarray.length - 1 ? '1.5rem' : '',
          }"
          @click="changepage(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="bg-filterbar" v-if="isspread" @click="handerClickOutside"></div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      pc: null, //是否是pc
      isspread: false, //移动端nav是否展开
      navarray: [
        {
          name: "简介",
          url: "/",
          title: "introduction",
          detail: [
            { title: "公司简介", mark: "introduce" },
            { title: "使命及愿景", mark: "mv" },
            { title: "企业文化", mark: "culturetitle" },
            { title: "发展大事记", mark: "eventspart" },
            { title: "合作伙伴", mark: "partners" },
          ],
          showitem: false,
        },
        {
          name: "业务",
          url: "/Business/",
          title: "business",
          detail: [
            { title: "面向医生", mark: "doctor" },
            { title: "面向患者", mark: "patient" },
            { title: "面向企业", mark: "company" },
          ],
          showitem: false,
        },
        { name: "新媒体", url: "/NewMedia/", title: "NewMedia" },
        {
          name: "企业责任",
          url: "/Responsibility/",
          title: "responsibility",
          detail: [
            { title: "抗疫行动", mark: "action" },
            { title: "共建患者教育中心", mark: "centre" },
            { title: "发行患者指南手册", mark: "handbook" },
          ],
          showitem: false,
        },
        { name: "加入我们", url: "/Joinus/", title: "Joinus" },
      ],
      preventDefault(e) {
        e.preventDefault();
      },
    };
  },
  watch: {
    isspread(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
        document.addEventListener("touchmove", this.preventDefault, {
          passive: false,
        }); //禁止页面滑动
      } else {
        document.body.style.overflow = "auto"; //出现滚动条
        document.removeEventListener("touchmove", this.preventDefault, {
          passive: false,
        });
      }
    },
  },
  methods: {
    menuBtn(className, item) {
      // console.log(222);
      let obj = JSON.stringify({ className: className, title: item.url });
      sessionStorage.setItem("nav", obj);
      this.$emit("jumpMenu", { className, item });

      // console.log(111);
      // console.log(className);
    },
    unshowli(item) {
      // console.log(11111);
      this.navarray.forEach((v) => {
        v.showitem = false;
      });
      item.showitem = false;
    },
    showli(item) {
      // console.log(22222)
      this.navarray.forEach((v) => {
        v.showitem = false;
      });
      item.showitem = true;
    },
    handerClickOutside() {
      this.isspread = false;
    },
    tolink() {
      if (location.pathname != "/") {
        this.$router.push("/");
      }
    },
    changepage(item) {
      // sessionStorage.removeItem("nav");
      document.body.style.overflow = "auto"; //出现滚动条
      document.removeEventListener("touchmove", this.preventDefault, {
        passive: false,
      });
      if (window.location.pathname != item.url) {
        this.$router.push(item.url);
      } else {
        this.isspread = false;
      }
    },
    topread() {
      if (this.isspread) {
        this.isspread = false;
      } else {
        this.isspread = true;
      }
    },
    //判断是否移动端访问
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
  },
  created() {
    this.pc = this.IsPC();
    // console.log(this.pc);
  },
  props: {
    titlename: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "../assets/px.less";
// pc端
.content {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: @px60;
  font-size: @px16;
  &-box {
    width: @px1200;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.left_logo {
  cursor: pointer;
  width: @px102;
  height: @px36;
  background: url(//bosdev.liangyihui.net/temp/1617699379.png) no-repeat;
  background-size: 100% 100%;
}
.right_ul {
  font-weight: bold;
  line-height: @px60;
  height: @px60;
  cursor: pointer;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.right_li {
  position: relative;
  display: inline-block;
  color: #454556;
  margin-right: @px48;
  .rightline {
    position: absolute;
    bottom: @px12;
    width: 100%;
    background-color: rgba(205, 0, 112, 1);
    border-radius: @px1;
    height: @px2;
  }
  &-float {
    border-radius: @px4;
    width: @px200;
    border: @px1 solid rgba(229, 229, 232, 1);
    position: absolute;
    left: 50%;
    margin-left: -6.25rem;
    // width: 100%;
    // height: 100px;
    background-color: #fff;
    &-item {
      z-index: 1001;
      // height: 48px;
      line-height: @px48;
      font-size: @px16;
      color: #454556;
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item:hover {
      background-color: #f4f4fa;
    }
  }
}
.right_li:last-child {
  margin-right: 0;
}
// 移动端
.mbcontent {
  width: 100%;
  padding: 0 @px20;
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 102;
  &-box {
    padding: @px12 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.mbleft_logo {
  width: @px102;
  height: @px36;
  background: url(//bosdev.liangyihui.net/temp/1617699379.png) no-repeat;
  background-size: 100% 100%;
}
.mbrightflag {
  width: @px24;
  height: @px24;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mb_ul {
  background-color: #fff;
  overflow: hidden;
  padding: @px24 0;

  .mb_li {
    font-size: @px16;
    line-height: @px24;
    color: #454556;
    font-weight: bold;
  }
}
.bg-filterbar {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 32;
  left: 0;
  top: @px43;
}
</style>
